import React from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { useBridgeState } from '../../bridge';

const DesktopSwitcher = ({ className }) => {
  const { t } = useTranslation();
  const { isWebView } = useBridgeState();

  const desktopLinkOnClickHandler = e => {
    e.preventDefault();
    const domain = process.env.COOKIES_DOMAIN || '.bina.az';
    const date = new Date();
    const setCookie = (name, expiresDate) => {
      document.cookie = `${name}; expires=${expiresDate.toUTCString()}; domain=${domain}`;
      return null;
    };
    setCookie('mobile_site= ', new Date(0));
    date.setDate(date.getDate() + 1);
    setCookie('force_desktop_site=yes', date);
    Router.reload();
  };

  if (isWebView) {
    return null;
  }

  return (
    <button
      onClick={desktopLinkOnClickHandler}
      className={className}
      data-cy="menu-link"
    >
      {t('desktop_switcher.full_site')}
    </button>
  );
};

DesktopSwitcher.propTypes = {
  className: PropTypes.string
};

export default DesktopSwitcher;
