import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const PromotionInfo = ({ message, additionalClasses }) => {
  return (
    <div className={cn('promotion__info', additionalClasses)}>{message}</div>
  );
};

PromotionInfo.propTypes = {
  message: PropTypes.string,
  additionalClasses: PropTypes.string
};

export default PromotionInfo;
