import { useEffect } from 'react';

export default ({ postMessageName, handler }) => {
  useEffect(() => {
    const handleEvent = event =>
      event.data === postMessageName && handler(postMessageName);
    window.addEventListener('message', handleEvent);
    return () => window.removeEventListener('message', handleEvent);
  }, []);

  return null;
};
