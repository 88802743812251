import React from 'react';
import PropTypes from 'prop-types';

const ContactItem = ({ icon, text, index, path, isButton, clickHandler }) => {
  return isButton ? (
    <div
      role={'button'}
      tabIndex={-1}
      className={'contacts-item'}
      onClick={clickHandler}
      onKeyPress={clickHandler}
      data-cy="contact-us-link"
    >
      <i className={'contacts-item__icon'}>{icon}</i>
      <span className={'contacts-item__title'}>{text}</span>
    </div>
  ) : (
    <a
      href={path}
      role={'button'}
      tabIndex={index}
      className={'contacts-item'}
      data-cy="contact-us-link"
    >
      <i className={'contacts-item__icon'}>{icon}</i>
      <span className={'contacts-item__title'}>{text}</span>
    </a>
  );
};

ContactItem.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.string,
  index: PropTypes.number,
  path: PropTypes.string,
  isButton: PropTypes.bool,
  clickHandler: PropTypes.func
};

export default ContactItem;
