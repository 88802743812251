import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useMutation } from '@apollo/client';
import {
  openSuccessPromotionAlertModal,
  togglePromotionModalView,
  toggleRestoreAlertModal
} from '../actions';
import Bugsnag from '../utils/bugsnag';
import { payForItemQuery, prolongItemQuery } from '../queries';

const useTogglePromotionModalView = item => {
  const dispatch = useDispatch();
  const router = useRouter();
  const splitPath = router.asPath.split('#');
  const mainPartOfLocation = splitPath[0];
  const restPartOfLocation = splitPath[1];
  const isPremiumView = restPartOfLocation === 'service_feature';
  const isRestoreView = restPartOfLocation === 'restore';
  const isPaymentMethodView = restPartOfLocation === 'limit_payment';
  const isPersonalAdsLimitView = restPartOfLocation === 'personal_ads';
  const { id } = router.query;
  const isPayForLimitByPersonalAdsAvailable = item?.actions?.includes('PAY');
  const isProlongByPersonalAdsPaymentAvailable =
    item?.actions?.includes('PROLONG');
  const [prolongItem] = useMutation(prolongItemQuery);
  const [payForItem] = useMutation(payForItemQuery);

  const onCloseHandler = () => {
    dispatch(togglePromotionModalView(false));

    router.push(`${mainPartOfLocation}`, undefined, {
      shallow: true
    });
  };

  const openCorrectView = route => {
    dispatch(togglePromotionModalView(true));

    router.push(`${mainPartOfLocation}#${route}`, undefined, {
      shallow: true
    });
  };

  const onToggleRestoreAlertModal = isOpen => {
    dispatch(toggleRestoreAlertModal(isOpen));
  };

  const onOpenRestoreViewHandler = () => {
    if (isProlongByPersonalAdsPaymentAvailable) {
      onToggleRestoreAlertModal(true);
      return;
    }
    openCorrectView('restore');
  };

  const onOpenLimitPaymentViewHandler = () => {
    if (isPayForLimitByPersonalAdsAvailable) {
      openCorrectView('personal_ads');
      return;
    }
    openCorrectView('limit_payment');
  };

  const onDeclineRestorePayment = () => {
    onToggleRestoreAlertModal(false);
  };

  const onAcceptRestorePaymentHandleClick = async () => {
    onToggleRestoreAlertModal(false);
    prolongItem({
      variables: {
        clientMutationId: id,
        itemId: id
      }
    })
      .then(data => {
        if (data.data.prolongItem.item.state === 'PUBLISHED') {
          dispatch(openSuccessPromotionAlertModal('restore'));
        }
      })
      .catch(error => {
        Bugsnag.notify(error);
      });
  };

  const onLimitPaymentHandleClick = () => {
    payForItem({
      variables: {
        clientMutationId: id,
        itemId: id
      }
    })
      .then(data => {
        if (data.data.payForItem.item.state === 'PENDING') {
          onCloseHandler();
          dispatch(openSuccessPromotionAlertModal('limit_payment'));
        }
      })
      .catch(error => {
        Bugsnag.notify(error);
      });
  };

  return {
    onCloseHandler,
    openCorrectView,
    splitPath,
    isPremiumView,
    isPaymentMethodView,
    isRestoreView,
    isPersonalAdsLimitView,
    onOpenRestoreViewHandler,
    onOpenLimitPaymentViewHandler,
    onAcceptRestorePaymentHandleClick,
    onDeclineRestorePayment,
    onLimitPaymentHandleClick
  };
};

export default useTogglePromotionModalView;
