import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContactsItem from './ContactsItem';
import EmailIcon from '../../public/static/images/social/email.svg';
import FacebookIcon from '../../public/static/images/social/facebook.svg';
import InstagramIcon from '../../public/static/images/social/instagram.svg';
import PhoneIcon from '../../public/static/images/phone_call_brown-icon.svg';
import Icon from '../Icon';
import CONFIG from '../../config';
import { formatNumber, getCallCenterPhones } from '../../utils/helpers';

const ContactsList = ({
  onPhonesItemClickHandler,
  showPhone,
  currentItemPhones,
  isCurrentItemPage
}) => {
  const { t } = useTranslation();
  const {
    contacts: { email, instagramLink, facebookLink }
  } = CONFIG;
  const phonesList = isCurrentItemPage
    ? currentItemPhones
    : getCallCenterPhones();

  const contactsList = [
    {
      icon: PhoneIcon,
      path: phonesList.length > 1 ? '' : phonesList[0].telUrl,
      title: t('common.call'),
      name: 'phone',
      isButton: phonesList.length > 1,
      clickHandler:
        phonesList.length > 1 ? onPhonesItemClickHandler : undefined,
      iconW: 17,
      iconH: 17
    },
    {
      icon: EmailIcon,
      path: `mailto:${email}`,
      title: t('common.write'),
      name: 'email',
      iconW: 16,
      iconH: 13
    },
    {
      icon: FacebookIcon,
      path: facebookLink,
      title: 'Facebook',
      name: 'facebook',
      iconW: 8,
      iconH: 16
    },
    {
      icon: InstagramIcon,
      path: instagramLink,
      title: 'Instagram',
      name: 'instagram',
      iconW: 16,
      iconH: 16
    }
  ];

  if (showPhone && phonesList.length > 1) {
    return phonesList.map((phone, i) => (
      <ContactsItem
        icon={
          <Icon
            width={17}
            height={17}
            IconComponent={PhoneIcon}
            indent={false}
          />
        }
        text={isCurrentItemPage ? phone : phone.tel}
        key={`phone-number-${i}`}
        path={isCurrentItemPage ? `tel:+${formatNumber(phone)}` : phone.telUrl}
        index={0}
      />
    ));
  }

  return contactsList.map(
    ({ icon, title, name, path, isButton, clickHandler, iconW, iconH }, i) => (
      <ContactsItem
        icon={
          <Icon
            width={iconW}
            height={iconH}
            IconComponent={icon}
            indent={false}
          />
        }
        text={title}
        key={name}
        path={path}
        index={i}
        isButton={isButton}
        clickHandler={clickHandler}
      />
    )
  );
};

ContactsList.propTypes = {
  onPhonesItemClickHandler: PropTypes.func,
  showPhone: PropTypes.bool,
  currentItemPhones: PropTypes.array,
  isCurrentItemPage: PropTypes.bool
};

export default ContactsList;
