import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import CloseIcon from '../../../public/static/images/item/close.svg';

const PromotionHeader = ({ onCloseHandler, icon, title }) => {
  return (
    <div className="promotion__header">
      <i
        className="promotion__header-close-icon"
        onClick={onCloseHandler}
        onKeyPress={onCloseHandler}
        role="button"
        tabIndex="-1"
      >
        <Icon
          indent={false}
          IconComponent={CloseIcon}
          width={15}
          height={15}
          color="brown"
        />
      </i>
      <div className="promotion__header-title">
        <i>
          {icon && <Icon indent IconComponent={icon} width={18} height={16} />}
        </i>
        <span>{title}</span>
      </div>
    </div>
  );
};

PromotionHeader.propTypes = {
  onCloseHandler: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string
};

export default PromotionHeader;
