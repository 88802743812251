import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HeaderPlaceholder from '../Placeholders/HeaderPlaceholder';
import BinaModal from '../ReactModal';
import useToggle from '../../hooks/useToggle';
import { selectSideMenuModalState } from '../../store/selectors';
import WhiteHeader from '../WhiteHeader';
import MenuList from '../MenuList';
import ContactsList from '../ContactInfoModal/ContactsList';

const ContactInfoModal = dynamic(() => import('../ContactInfoModal'), {
  loading: HeaderPlaceholder,
  ssr: false
});

const SideMenuModal = () => {
  const { t } = useTranslation();
  const [isOpenModal, toggleModal] = useToggle(false);
  const [showPhone, setShowPhone] = useState(false);
  const { isVisible: isVisibleSideMenuModal } = useSelector(
    selectSideMenuModalState
  );

  const onPhonesItemClickHandler = () => {
    setShowPhone(prev => !prev);
  };

  const handleToggleModal = () => {
    toggleModal();
    setShowPhone(false);
  };

  return (
    <BinaModal
      modalId={'side-menu'}
      isOpen={isVisibleSideMenuModal}
      overlayClassName="modal__overlay--side-menu"
      contentClassName="modal__content--side-menu"
    >
      <div
        className="modal__content--side-menu-container"
        data-cy="side-menu-container"
      >
        <WhiteHeader headerTitle={t('common.more')} />
        <MenuList onPhoneClickHandler={toggleModal} />
        <ContactInfoModal
          isOpen={isOpenModal}
          closeHandler={handleToggleModal}
          title={showPhone ? t('common.call') : t('common.feedback')}
        >
          <ContactsList {...{ onPhonesItemClickHandler, showPhone }} />
        </ContactInfoModal>
      </div>
    </BinaModal>
  );
};

export default SideMenuModal;
