import { Component } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

export default class Portal extends Component {
  componentDidMount() {
    this.node.className = this.props.portalClassName;
    this.node.setAttribute('data-cy', `modal-${this.props.id}`);
    document.body.appendChild(this.node);
  }

  componentWillUnmount() {
    document.body.removeChild(this.node);
  }

  render() {
    if (!process.browser) {
      return null;
    }

    if (!this.node) {
      this.node = document.createElement('div');
    }

    return createPortal(this.props.children, this.node);
  }
}

Portal.propTypes = {
  children: PropTypes.node,
  portalClassName: PropTypes.string,
  id: PropTypes.string
};
