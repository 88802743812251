import React, { useEffect, useState, memo } from 'react';
import PropTypes from 'prop-types';
import noScroll from 'no-scroll';
import Portal from './Portal';

const ReactModal = ({
  children,
  isOpen,
  onAfterOpen,
  onRequestClose,
  overlayClassName,
  contentClassName,
  role,
  modalClassScope,
  modalId
}) => {
  if (!process.browser) {
    return null;
  }
  const classScope = modalClassScope || 'modal';
  const modalClassNames = {
    portal: `${classScope}__portal`,
    bodyOpen: `${classScope}__body--open`,
    overlay: `${classScope}__overlay`,
    content: `${classScope}__content`
  };
  const [afterOpen, setAfterOpen] = useState(false);
  const [shouldClose, setShouldClose] = useState(null);
  const [noScrollingFlag, setNoScrollingFlag] = useState(false);

  const beforeOpen = () => {
    if (document.body.classList.contains(modalClassNames.bodyOpen)) {
      setNoScrollingFlag(true);
      return false;
    }

    document.body.classList.add(modalClassNames.bodyOpen);
    noScroll.on();
  };

  const afterClose = () => {
    if (noScrollingFlag) {
      setNoScrollingFlag(false);
      return false;
    }

    document.body.classList.remove(modalClassNames.bodyOpen);
    noScroll.off();
  };

  const openModal = () => {
    beforeOpen();
    setAfterOpen(true);
  };

  const requestClose = () => {
    onRequestClose && onRequestClose();
  };

  const handleOverlayOnClick = () => {
    if (shouldClose === null) {
      setShouldClose(true);
    }
  };

  const handleContentOnClick = event => {
    setShouldClose(false);
    event.stopPropagation();
  };

  const buildClassNames = (which, additional) => {
    const classNames =
      typeof additional === 'object'
        ? additional
        : {
            base: modalClassNames[which],
            afterOpen: `${modalClassNames[which]}--after-open`
          };
    let className = classNames.base;

    if (onAfterOpen && afterOpen) {
      className = `${className} ${classNames.afterOpen}`;
    }

    return typeof additional === 'string' && additional
      ? `${className} ${additional}`
      : className;
  };

  useEffect(() => {
    if (isOpen) {
      openModal();
    }

    return () => {
      if (isOpen) afterClose();
    };
  }, [isOpen, noScrollingFlag]);

  useEffect(() => {
    if (afterOpen && onAfterOpen) {
      onAfterOpen();
    }
  }, [afterOpen]);

  useEffect(() => {
    if (shouldClose) {
      requestClose();
    }

    setShouldClose(null);
  }, [shouldClose]);

  return (
    <Portal portalClassName={modalClassNames.portal} id={modalId}>
      {isOpen && (
        <>
          <div
            className={buildClassNames('overlay', overlayClassName)}
            onClick={handleOverlayOnClick}
            onKeyPress={handleOverlayOnClick}
            role={role}
          >
            <div
              className={buildClassNames('content', contentClassName)}
              role={role}
              onClick={handleContentOnClick}
              onKeyPress={handleContentOnClick}
            >
              {children}
            </div>
          </div>
        </>
      )}
    </Portal>
  );
};

ReactModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isOpen: PropTypes.bool.isRequired,
  onAfterOpen: PropTypes.func,
  onRequestClose: PropTypes.func,
  overlayClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  role: PropTypes.string,
  modalClassScope: PropTypes.string,
  modalId: PropTypes.string
};

ReactModal.defaultProps = {
  isOpen: false,
  role: 'dialog'
};

export default memo(ReactModal);
