import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { toggleSideMenuModal } from '../../actions';

const MenuItem = ({ path = '', text, as, clickHandler, children }) => {
  const dispatch = useDispatch();

  const oLinkItemClickHandler = () => {
    dispatch(toggleSideMenuModal(false));
  };

  return (
    <li>
      {(() => {
        switch (true) {
          case !!children:
            return children;
          case !!clickHandler:
            return (
              <div
                role="button"
                tabIndex={-1}
                onClick={clickHandler}
                onKeyPress={clickHandler}
                className={cn('m-nav__link', 'm-nav__link-bottom')}
                data-cy="menu-link"
              >
                {text}
              </div>
            );
          default:
            return (
              <Link
                href={path}
                as={as}
                replace
                className="m-nav__link"
                onClick={oLinkItemClickHandler}
                onKeyPress={oLinkItemClickHandler}
                data-cy="menu-link"
              >
                {text}
              </Link>
            );
        }
      })()}
    </li>
  );
};

MenuItem.propTypes = {
  path: PropTypes.string,
  as: PropTypes.string,
  text: PropTypes.string,
  clickHandler: PropTypes.func,
  children: PropTypes.node
};

export default MenuItem;
