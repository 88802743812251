import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import ChangeLang from './ChangeLang';
import DesktopSwitcher from '../DesktopSwitcher';
import MenuItem from './MenuItem';

const MenuList = ({ onPhoneClickHandler }) => {
  const { i18n, t } = useTranslation();

  const menuListItems = [
    {
      name: 'rent',
      path: '/[...search]?url=/alqi-satqi',
      as: '/alqi-satqi',
      text: t('main_menu.sale')
    },
    {
      name: 'lease',
      path: '/[...search]?url=/kiraye',
      as: '/kiraye',
      text: t('main_menu.lease')
    },
    {
      name: 'agencies',
      path: '/agencies',
      as: '/agentlikler',
      text: t('main_menu.agencies')
    },
    {
      name: 'residences',
      path: '/residences',
      as: '/yasayis-kompleksleri',
      text: t('main_menu.residences')
    },
    {
      name: 'connect-us',
      path: '',
      as: '',
      text: t('common.contact_us'),
      clickHandler: onPhoneClickHandler
    }
  ];

  return (
    <div className="m-nav__list">
      <ul>
        <MenuItem>
          <ChangeLang
            i18n={i18n}
            t={t}
            className={cn('m-nav__link', 'm-nav__link-top')}
          />
        </MenuItem>
        {menuListItems.map(({ name, path, text, as, clickHandler }) => (
          <MenuItem key={name} {...{ path, text, as, clickHandler }} />
        ))}
        <MenuItem>
          <DesktopSwitcher
            className={cn('m-nav__link', 'm-nav__link-bottom-transparent')}
            cypress="menu-link"
          />
        </MenuItem>
      </ul>
    </div>
  );
};

MenuList.propTypes = {
  onPhoneClickHandler: PropTypes.func
};

export default MenuList;
