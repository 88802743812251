import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const StickyElement = ({
  children,
  bottomPos,
  topOffset,
  bottomOffset,
  elementIdToObserve
}) => {
  const wrapperRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const isIphone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
  // it fixes extra padding in iPhone safari
  const additionalBottomSpace = isIphone ? 60 : 0;

  useEffect(() => {
    const wrapperEl = wrapperRef.current;
    const windowHeight = window.innerHeight;
    const elementToObserve =
      document.getElementById(elementIdToObserve) || document.body;
    let isTimeToSet;

    const checkPosition = () => {
      const scrollTop = window.scrollY;

      if (!bottomPos) {
        isTimeToSet = scrollTop >= wrapperEl?.offsetTop - topOffset;
      } else {
        isTimeToSet =
          windowHeight + scrollTop + additionalBottomSpace <
          wrapperEl?.offsetTop + wrapperEl?.clientHeight - bottomOffset;
      }

      setIsSticky(isTimeToSet);
    };

    checkPosition();

    if (elementToObserve && window.ResizeObserver) {
      const { ResizeObserver } = window; // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/observe
      const observer = new ResizeObserver(() => {
        checkPosition();
      });
      observer.observe(elementToObserve);
    }

    window.addEventListener('scroll', checkPosition);
    window.addEventListener('resize', checkPosition);
    return () => {
      window.removeEventListener('scroll', checkPosition);
      window.removeEventListener('resize', checkPosition);
    };
  }, []);

  return typeof children === 'function'
    ? children(isSticky, wrapperRef)
    : children;
};

StickyElement.displayName = 'StickyElement';

StickyElement.defaultProps = {
  bottomPos: false,
  topOffset: 0,
  bottomOffset: 0
};

StickyElement.propTypes = {
  children: PropTypes.func,
  bottomPos: PropTypes.bool,
  topOffset: PropTypes.number,
  bottomOffset: PropTypes.number,
  isResizeObserver: PropTypes.bool,
  elementIdToObserve: PropTypes.string
};

export default StickyElement;
